import type { BaseInstance } from '@pigello/pigello-matrix';
import type { StateCreator } from 'zustand';
import type { TrackedBulk } from '../types';
import {
  BULK_TAB_ORIGIN,
  establishConnection,
  getTabTitleText,
  getTrackedBulk,
  getWindowTarget,
  getWindowUrl,
  regainWindowReference,
  registerBulkTabOnWindow,
  sendMessage,
  type BulkTabOpenOptions,
  type BulkTabSlice,
} from './utils';

export const createBulkTabSlice: StateCreator<BulkTabSlice> = (set, get) => ({
  trackedBulks: {},
  hasEventListener: false,
  initialize: () => {
    get().handleGlobalEventListener();
    // biome-ignore lint/complexity/noForEach: can optimize to for of loop if necessary
    Object.values(get().trackedBulks).forEach((trackedBulk) => {
      if (!trackedBulk.active) return get().clearTrackedBulk(trackedBulk);

      const success = regainWindowReference(trackedBulk.id);

      if (!success) return get().clearTrackedBulk(trackedBulk);

      establishConnection(trackedBulk.id, true);
    });
  },
  clearTrackedBulk: (trackedBulk) => {
    const newTracked = get().trackedBulks;

    delete newTracked[trackedBulk.id];

    set({
      trackedBulks: newTracked,
    });
  },
  handleGlobalEventListener: () => {
    if (get().hasEventListener || typeof window === 'undefined') return;

    window.addEventListener('message', (evt) => {
      if (evt.origin !== BULK_TAB_ORIGIN) return;

      if (typeof evt.data !== 'object' || !('_bulk_tab_win_id' in evt.data)) {
        console.log('malformed window message', evt.data);
        return;
      }

      const listeners = get().listeners;

      const winId = evt.data._bulk_tab_win_id;

      if (!(winId in listeners)) return;

      if (typeof evt.data !== 'object' || !('id' in evt.data)) {
        console.log('malformed window message', evt.data);
        return;
      }

      if (!(evt.data.id in listeners[winId])) return;

      // biome-ignore lint/complexity/noForEach: can optimize to for of loop if necessary
      listeners[winId][evt.data.id].forEach((cb) => cb(evt.data, evt));
    });

    set({
      hasEventListener: true,
    });
  },
  onUnload: () => {
    // biome-ignore lint/complexity/noForEach: can optimize to for of loop if necessary
    Object.values(get().trackedBulks).forEach((trackedBulk) => {
      if (!trackedBulk.active) return;

      sendMessage(trackedBulk.id, {
        id: 'ON_MAIN_WINDOW_UNLOAD',
      });
    });
  },
  listeners: {},
  onMessage: (winId, msgId, cb) => {
    const currentListeners = get().listeners;

    if (!(winId in currentListeners)) currentListeners[winId] = {};

    if (!(msgId in currentListeners[winId])) {
      currentListeners[winId][msgId] = [cb];
    } else {
      currentListeners[winId][msgId] = [...currentListeners[winId][msgId], cb];
    }
  },
  openNewBulk: <Instance extends BaseInstance>(
    opts: BulkTabOpenOptions<Instance>
  ) => {
    if (typeof window === 'undefined') return;

    const trackedBulk: TrackedBulk = {
      id: opts.winId ?? `bulk-tab-${crypto.randomUUID()}`,
      displayName: `${getTabTitleText(opts.mode)} - ${opts.config.verboseNamePlural}`,
      modelName: opts.config.modelName,
      mode: opts.mode,
      active: false,
    };

    if ('instances' in opts && !opts.winId && opts.instances) {
      if (opts.instances.length === 0) {
        console.warn(
          'Unable to open bulk tab for mode',
          opts.mode,
          'instances.length was 0'
        );
        return;
      }

      if (typeof opts.instances[0] === 'string')
        trackedBulk.instanceIds = opts.instances as string[];
      else
        trackedBulk.instanceIds = (opts.instances as Instance[]).map(
          (inst) => inst.id
        );
    }

    if (opts.mode === 'download') {
      trackedBulk.downloadData = {
        columns: opts.columns,
        settings: opts.settings,
      };
    }

    const win = window.open(
      getWindowUrl(trackedBulk),
      getWindowTarget(trackedBulk)
    );

    if (!win) return;

    registerBulkTabOnWindow(trackedBulk.id, win);

    get().handleGlobalEventListener();

    const currentTrackedBulks = get().trackedBulks;

    set({
      trackedBulks: {
        ...currentTrackedBulks,
        [trackedBulk.id]: trackedBulk,
      },
    });

    establishConnection(trackedBulk.id);
  },
  activateTrackedBulk: (winId) => {
    const trackedBulk = getTrackedBulk(winId);

    if (!trackedBulk) {
      sendMessage(winId, {
        id: 'TERMINATE_WINDOW',
      });
      return;
    }
    trackedBulk.active = true;

    set({
      trackedBulks: {
        ...get().trackedBulks,
        [trackedBulk.id]: trackedBulk,
      },
    });
  },
});
