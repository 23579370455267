// Desktop/pigello-code/pigello-next/requests/api/fetch-get-list.ts

import { getConfig } from '@/lib/get-config';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';
import { NEVER_FETCH_NESTED_FIELDS } from '../constants';
import { chunkArray, extractNestedIds, isThin } from '../hooks/utils';
import type { ListResponse } from '../types';
import { getList } from './get-list';
import type { GetListParams } from './types';

/**
 * Interface for the fetchGetList function options.
 */
interface FetchGetListOptions<TInstance extends BaseInstance>
  extends GetListParams {
  modelName: ModelName;
  fetchAllManyRelations?: boolean;
  nested?: (keyof TInstance)[];
  waitForAllNested?: boolean;
}

/**
 * Interface for nested fetch queries.
 */
interface NestedFetchQuery<TInstance extends BaseInstance> {
  fieldName: keyof TInstance;
  relationModelName: ModelName;
  batchedIds: string[][];
}

/**
 * Interface for fetched nested data.
 */
interface FetchedNestedData<TInstance extends BaseInstance> {
  fieldName: keyof TInstance;
  data: TInstance[];
}

/**
 * Fetches a list of items along with their nested related data.
 *
 * @param options - Configuration options for fetching the list.
 * @returns A promise that resolves to a ListResponse containing the merged data and metadata.
 * @throws Will throw an error if the configuration for the model is not found or if any nested relation field is invalid.
 */
export async function getListNested<TInstance extends BaseInstance>(
  options: FetchGetListOptions<TInstance>
): Promise<ListResponse<TInstance>> {
  const {
    modelName,
    nested = [],
    fetchAllManyRelations = false,
    queryParams,
    overrideUrl,
    overrideUrlReplaceAll,
    waitForAllNested = false,
  } = options;

  // Retrieve configuration for the specified model
  const config = getConfig<ModelName, TInstance>(modelName, true);

  if (!config) {
    throw new Error(`No config found for modelName: ${modelName}`);
  }

  // Fetch the main list data
  const mainListResponse = await getList<TInstance>({
    modelName,
    queryParams,
    overrideUrl,
    overrideUrlReplaceAll,
    signal: undefined,
  });

  const mainList = mainListResponse.list;
  const meta = mainListResponse.meta;

  // Filter out nested fields that should never be fetched
  const filteredNested = nested.filter((fieldName) => {
    return !NEVER_FETCH_NESTED_FIELDS.find((f) => f === fieldName);
  }) as (keyof TInstance)[];

  // Extract nested IDs from the main list
  const nestedIds = extractNestedIds(
    mainList,
    filteredNested,
    fetchAllManyRelations
  );

  // Prepare nested fetch queries
  const nestedToFetch: NestedFetchQuery<TInstance>[] = [];
  for (const [fieldName, idsList] of nestedIds.entries()) {
    const fieldConfig = config.fields[fieldName];
    if (!fieldConfig || !('relationConfig' in fieldConfig)) {
      throw new Error(
        `Field ${String(
          fieldName
        )} is not a relation field in model ${modelName}`
      );
    }

    const relationModelName = fieldConfig.relationConfig as ModelName;
    const uniqueIds = Array.from(new Set(idsList)); // Ensure uniqueness

    // Chunk IDs into batches of 40
    const batchedIds = chunkArray(uniqueIds, 40);

    nestedToFetch.push({
      fieldName,
      relationModelName,
      batchedIds,
    });
  }

  // Function to fetch nested data batches
  const fetchNestedData = async (
    fieldName: keyof TInstance,
    relationModelName: ModelName,
    batch: string[]
  ): Promise<FetchedNestedData<TInstance>> => {
    const data = await getList<TInstance>({
      modelName: relationModelName,
      queryParams: {
        filters: {
          id: {
            __in: batch.join(','),
          },
        },
      },
      overrideUrl: undefined,
      overrideUrlReplaceAll: undefined,
      signal: undefined,
    });

    return {
      fieldName,
      data: data.list,
    };
  };

  // Fetch all nested data concurrently
  const allNestedDataPromises: Promise<FetchedNestedData<TInstance>>[] = [];
  for (const fetchQuery of nestedToFetch) {
    const { fieldName, relationModelName, batchedIds } = fetchQuery;
    for (const batch of batchedIds) {
      if (batch.length > 0) {
        allNestedDataPromises.push(
          fetchNestedData(fieldName, relationModelName, batch)
        );
      }
    }
  }

  // If waitForAllNested is true, wait for all nested data to be fetched
  let fetchedNestedData: FetchedNestedData<TInstance>[] = [];
  if (waitForAllNested || nestedToFetch.length > 0) {
    fetchedNestedData = await Promise.all(allNestedDataPromises);
  }

  // Aggregate fetched nested data using Maps for efficient access
  const aggregatedNestedData = new Map<
    keyof TInstance,
    Map<string, TInstance>
  >();

  for (const fetched of fetchedNestedData) {
    const { fieldName, data } = fetched;
    if (!aggregatedNestedData.has(fieldName)) {
      aggregatedNestedData.set(fieldName, new Map());
    }
    const fieldMap = aggregatedNestedData.get(fieldName)!;
    for (const item of data) {
      fieldMap.set(item.id, item);
    }
  }

  // Merge nested data into main list
  const mergedList: TInstance[] = mainList.map((instance) => {
    const newInstance = { ...instance };

    for (const fieldName of filteredNested) {
      const related = instance[fieldName];
      const relatedDataMap = aggregatedNestedData.get(fieldName);

      if (Array.isArray(related)) {
        newInstance[fieldName] = related.map((item: TInstance) => {
          if (isThin(item) && relatedDataMap) {
            const relatedData = relatedDataMap.get(item.id);
            return relatedData ? { ...item, ...relatedData } : item;
          }
          return item;
        }) as (typeof newInstance)[typeof fieldName];
      } else if (isThin(related) && relatedDataMap) {
        const relatedData = relatedDataMap.get(related.id);
        newInstance[fieldName] = relatedData
          ? ({ ...relatedData } as typeof related)
          : related;
      }
    }

    return newInstance;
  });

  return {
    list: mergedList,
    meta,
  };
}
