import { parseJsonFilter } from '@/hooks/use-table-filter/utils';
import { getGlobalFilterQuery } from '@/lib/api/globalFilter';
import { getConfig } from '@/lib/get-config';
import { type BaseInstance, type ModelName } from '@pigello/pigello-matrix';
import queryString from 'query-string';
import { fetchApi } from '.';
import type { ListArguments } from '../types';
import {
  buildListBackendUrl,
  handleApiResponse,
  sanitizeFilters,
} from './utils';

type GetCount = {
  modelName: ModelName;
  statisticsOptions?: string[];
  filters?: ListArguments['queryParams']['filters'];
  revalidate?: number;
  overrideUrl?: string;
  overrideUrlReplaceAll?: boolean;
  skipGlobalFilters?: boolean;
  signal?: AbortSignal;
};

export type StatisticsForTimeLine = {
  statistics: {
    count: number;
    debt_timeline?: {
      timeline: unknown;
      existing_reminder_fee_count: number;
      existing_debt_reminder_fee_count: number;
      existing_interest_fee_count: number;
    };
  };
};

const buildParams = (statisticsOptions?: string[]) => {
  return queryString.stringify(
    {
      _statistics_options: statisticsOptions,
      _statistics: true,
    },
    {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: 'comma',
    }
  );
};

export async function getCountForTimeLine({
  modelName,
  filters,
  statisticsOptions,
  revalidate,
  overrideUrl,
  overrideUrlReplaceAll,
  skipGlobalFilters,
  signal,
}: GetCount) {
  const config = getConfig<ModelName, BaseInstance>(modelName, true);
  if (!config) {
    throw new Error(`No config found for model ${modelName}`);
  }

  // Sanitize filters
  const sanitizedFilters = sanitizeFilters(filters);

  // Turn the sanitized filters into a string
  const sanitizedFiltersString = parseJsonFilter(sanitizedFilters ?? {});

  // Build backend URL
  const backendUrl = buildListBackendUrl(
    config,
    overrideUrl,
    overrideUrlReplaceAll
  );

  // Construct complete URL with global filters if applicable
  const globalFilterQuery =
    modelName !== 'organization'
      ? await getGlobalFilterQuery(skipGlobalFilters)
      : '';

  const completeUrl = `${backendUrl}${
    globalFilterQuery ? `${globalFilterQuery.replace('&', '?')}` : '?'
  }`;

  // Append JSON filters
  const urlWithFilters = `${completeUrl}&${sanitizedFiltersString.length > 0 ? sanitizedFiltersString : ''}`;

  const finalFinalUrl =
    urlWithFilters.endsWith('&') || urlWithFilters.endsWith('?')
      ? urlWithFilters.slice(0, -1)
      : urlWithFilters;

  const finalUrl = `${finalFinalUrl}&${buildParams(statisticsOptions)}`;

  const res = await fetchApi({
    method: 'GET',
    url: finalUrl,
    next: {
      revalidate,
    },
    signal,
  });

  const data = await handleApiResponse<StatisticsForTimeLine>(res);
  return data.statistics;
}
