import type {
  BaseInstance,
  RelationalFieldValue,
} from '@pigello/pigello-matrix';
import { ObjectKeys } from '../utils';

export const isThin = (obj?: Record<string, any> | null): boolean => {
  if (!obj) return true;
  if (Object.keys(obj).length !== 1) return false;
  return true;
};

/**
 * @description
 * Used to unwrapp and set the correct types on nested field values that is
 * returned when specifying the nested option on api hooks.
 *
 * @param relation - The relation you want to unwrapp.
 * @returns relation with the infered type if defined, otherwise return null
 * @example
 * ```tsx
 * const data = { data } = useGetApartment({nested: ['someNestedField']})
 * const someNestedField = unwrappRelationFieldValue(data.someNestedField);
 *       ^ SomeNestedFieldType | null                     ^ RelationFieldValue<SomeNestedFieldType>
 * ```
 */
export function unwrapRelationFieldValue<TInstance extends BaseInstance>(
  relation: RelationalFieldValue<TInstance>
) {
  if (
    relation !== undefined &&
    relation !== null &&
    // A relation will always include the id properties, "> 1" verifies that
    // the other properties are included and thus its a nested relation
    ObjectKeys(relation).length > 1
  )
    return relation as TInstance;
  return null;
}
