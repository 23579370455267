import { parseJsonFilter } from '@/hooks/use-table-filter/utils';
import { getGlobalFilterQuery } from '@/lib/api/globalFilter';
import { getConfig } from '@/lib/get-config';
import { type BaseInstance, type ModelName } from '@pigello/pigello-matrix';
import { fetchApi } from '.';
import type { ListArguments } from '../types';
import {
  buildListBackendUrl,
  handleApiResponse,
  sanitizeFilters,
} from './utils';

type GetCount = {
  modelName: ModelName;
  filters?: ListArguments['queryParams']['filters'];
  revalidate?: number;
  overrideUrl?: string;
  overrideUrlReplaceAll?: boolean;
  skipGlobalFilters?: boolean;
  signal?: AbortSignal;
};

export async function getCount({
  modelName,
  filters,
  revalidate,
  overrideUrl,
  overrideUrlReplaceAll,
  skipGlobalFilters,
  signal,
}: GetCount) {
  const config = getConfig<ModelName, BaseInstance>(modelName, true);
  if (!config) {
    throw new Error(`No config found for model ${modelName}`);
  }

  // Sanitize filters
  const sanitizedFilters = sanitizeFilters(filters);

  // Turn the sanitized filters into a string
  const sanitizedFiltersString = parseJsonFilter(sanitizedFilters ?? {});

  // Build backend URL
  const backendUrl = buildListBackendUrl(
    config,
    overrideUrl,
    overrideUrlReplaceAll
  );

  // Construct complete URL with global filters if applicable
  const globalFilterQuery =
    modelName !== 'organization'
      ? await getGlobalFilterQuery(skipGlobalFilters)
      : '';

  const completeUrl = `${backendUrl}${
    globalFilterQuery ? `${globalFilterQuery.replace('&', '?')}` : '?'
  }`;

  // Append JSON filters
  const urlWithFilters = `${completeUrl}&${sanitizedFiltersString.length > 0 ? sanitizedFiltersString : ''}`;

  const finalFinalUrl =
    urlWithFilters.endsWith('&') || urlWithFilters.endsWith('?')
      ? urlWithFilters.slice(0, -1)
      : urlWithFilters;

  const finalUrl = `${finalFinalUrl}&_statistics=true`;

  const res = await fetchApi({
    method: 'GET',
    url: finalUrl,
    next: {
      revalidate,
    },
    signal,
  });

  const data = await handleApiResponse<{
    statistics: { count: number };
  }>(res);
  return data.statistics.count;
}
