import {
  customFilterConfig,
  OPERATORS,
} from '@/components/table-filters/constants';
import { ObjectEntries } from '@/lib/utils';
import type { ColumnSort, SortingState } from '@tanstack/react-table';
import { get } from 'lodash';
import type { JsonFilter } from './types';

export const orderByToParams = (orderBy: SortingState) => {
  const order = orderBy?.map((obj) => {
    return obj.desc ? `-${obj.id}` : obj.id;
  });
  if (order?.length === 0) {
    return null;
  }
  return order;
};

export const parseOrderToClient = (order: string[]) => {
  return order?.map((o) => {
    const isDesc = o.startsWith('-');

    return {
      desc: isDesc,
      id: isDesc ? o.substring(1, o.length) : o,
    } as ColumnSort;
  });
};

export const parseJsonFilter = (filters: JsonFilter) => {
  const parsed = ObjectEntries(filters ?? {})
    .filter(([key]) => !(key.includes('custom_') && !key.includes('custom_id')))
    .flatMap(([key, value]) => {
      const fieldName = key;
      return ObjectEntries(value)
        .filter(([, val]) => val !== undefined && val !== '')
        .map(
          ([operator, val]) =>
            fieldName +
            (operator !== OPERATORS.NOOP[0] ? encodeURI(operator) : '') +
            '=' +
            encodeURI(val as string)
        );
    });

  let filterStr = parsed.join('&');
  const customFilters = ObjectEntries(filters ?? {})?.filter(
    ([key]) => key.includes('custom_') && !key.includes('custom_id')
  );

  for (const [searchKey, value] of customFilters) {
    if (Array.isArray(value['noop'])) {
      for (const noop of value['noop']) {
        const customFilter = get(customFilterConfig, `${searchKey}.${noop}`);
        if (customFilter) {
          filterStr += `&${encodeURI(customFilter)}`;
        }
      }
      return filterStr;
    }
    const customFilter = get(
      customFilterConfig,
      `${searchKey}.${value['noop']}`
    );
    if (customFilter) {
      filterStr += `&${encodeURI(customFilter)}`;
    }
  }

  return filterStr;
};
