import type { ModelName } from '@pigello/pigello-matrix';
import type { IdentifierMapping } from '..';

const getInvoiceMapping = (
  modelName: ModelName,
  non_rental_invoices?: boolean
): IdentifierMapping[string][string] => {
  return {
    created_invoices: {
      modelName,
      filters: {
        ...(non_rental_invoices && {
          rental_invoice: { noop: false },
        }),
      },
      timeline_attributes: {
        done: ['created_at'],
      },
    },
    attested_invoices: {
      modelName,
      filters: {
        ...(non_rental_invoices && {
          rental_invoice: { noop: false },
        }),
      },
      timeline_attributes: {
        done: ['attested_date'],
      },
    },
    reminder_invoices: {
      modelName,
      statistics_options: ['debt_timeline'],
      timeline_attributes: {
        pending: ['due_date'],
      },
      filters: {
        attested_date: { __isnull: false },
        closed_time: { __isnull: true },
      },
    },
    reminder_debt_invoices: {
      modelName,
      statistics_options: ['debt_timeline'],
      timeline_attributes: {
        pending: ['due_date'],
      },
      filters: {
        attested_date: { __isnull: false },
        closed_time: { __isnull: true },
      },
    },
    inkasso_invoices: {
      modelName,
      statistics_options: ['debt_timeline'],
      timeline_attributes: {
        pending: ['due_date'],
      },
      filters: {
        attested_date: { __isnull: false },
        closed_time: { __isnull: true },
      },
    },
    expiring_invoices: {
      modelName,
      timeline_attributes: {
        pending: ['due_date'],
      },
    },
  };
};

const getContractMapping = (
  modelName: ModelName
): IdentifierMapping[string][string] => {
  return {
    signed_contracts: {
      modelName,
      timeline_attributes: {
        done: ['signed_time'],
      },
    },
    ending_contracts: {
      modelName,
      timeline_attributes: {
        pending: ['lifetime_end_date'],
      },
    },
    cancelled_contracts: {
      modelName,
      timeline_attributes: {
        done: ['cancellation_signed_time'],
      },
    },
    distributed_for_signature: {
      modelName: 'signabledocument',
      content_type: `contracts.${modelName}`,
      timeline_attributes: {
        done: ['signing_started_time'],
      },
    },
  };
};

export const systemEventsIdentifierData: IdentifierMapping[string] = {
  invoices: getInvoiceMapping('invoice'),
  non_rental_invoices: getInvoiceMapping('invoice', true),
  invoice_payments: {
    registered_invoice_payments: {
      modelName: 'invoicepayment',
      timeline_attributes: {
        done: ['registered_date', 'created_at'],
      },
    },
    matched_invoice_payments: {
      modelName: 'invoicepayment',
      timeline_attributes: {
        done: ['matched_date'],
      },
    },
    split_from_payment: {
      modelName: 'invoicepayment',
      filters: {
        split_from_payment: { __isnull: false },
      },
      timeline_attributes: {
        done: ['created_at'],
      },
    },
  },
  imd: {
    measurements_received: {
      modelName: 'measurementseries',
      timeline_attributes: {
        done: ['created_at', 'registration_date'],
      },
      noFilterReturn: true,
    },
  },
  notifications: {
    failed_delivery: {
      modelName: 'notification',
      timeline_attributes: {
        done: ['failed_delivery_time'],
      },
      noFilterReturn: true,
    },
  },
  apartment_contracts: getContractMapping('apartmentcontract'),
  industrialpremises_contracts: getContractMapping(
    'industrialpremisescontract'
  ),
  parkingspot_contracts: getContractMapping('parkingspotcontract'),
  outdoorsection_contracts: getContractMapping('outdoorsectioncontract'),
  other_contracts: getContractMapping('othercontract'),
  block_contracts: getContractMapping('blockcontract'),
};
