import { ObjectKeys } from '@/lib/utils';
import { create } from 'zustand';
import { devtools, persist, type StorageValue } from 'zustand/middleware';
import { createBulkTabSlice } from './bulk-tab-slice';
import type { BulkTabSlice } from './bulk-tab-slice/utils';
import type { FileSlice } from './fileSlice';
import { createFileSlice } from './fileSlice';
import type { FilterState } from './filter-slice';
import { createFilterSlice } from './filter-slice';
import { createFormSlice, type FormSlice } from './form-slice';
import type { InstanceSlice } from './instanceSlice';
import { createInstanceSlice } from './instanceSlice';
import {
  createOffLoadingSlice,
  type OffLoadingSlice,
} from './off-loading-slice';
import type { PromptSlice } from './promptSlice';
import { createPromptSlice } from './promptSlice';

export const useInstanceStore = create<InstanceSlice>()(
  devtools(
    (...a) => ({
      ...createInstanceSlice(...a),
    }),
    {
      name: 'instance-store',
    }
  )
);

export const useOffLoadingStore = create<OffLoadingSlice>()(
  persist(
    devtools(
      (...a) => ({
        ...createOffLoadingSlice(...a),
      }),
      {
        name: 'off-loading-store',
      }
    ),
    {
      name: 'off-loading-store',
      version: 1,
      storage: {
        getItem: (key: string) => {
          const str = sessionStorage.getItem(key);
          if (!str) {
            return null;
          }
          const existingValue = JSON.parse(str);
          return {
            ...existingValue,
            state: {
              ...existingValue.state,
              offLoadedModels: Object.fromEntries(
                Object.keys(existingValue.state.offLoadedModels).map(
                  (modelName) => [
                    modelName,
                    new Set(existingValue.state.offLoadedModels[modelName]),
                  ]
                )
              ),
            },
          };
        },
        setItem: (name, newValue: StorageValue<OffLoadingSlice>) => {
          const offLoadedModels = ObjectKeys(newValue.state.offLoadedModels)
            .map((modelName) => ({
              modelName,
              ids: Array.from(newValue.state.offLoadedModels[modelName] ?? []),
            }))
            .reduce(
              (acc, curr) => ({ ...acc, [curr.modelName]: curr.ids }),
              {}
            );
          const str = JSON.stringify({
            ...newValue,
            state: {
              ...newValue.state,
              offLoadedModels,
            },
          });
          sessionStorage.setItem(name, str);
        },
        removeItem: (name) => sessionStorage.removeItem(name),
      },
    }
  )
);

export const usePromptStore = create<PromptSlice>()(
  devtools(
    (...a) => ({
      ...createPromptSlice(...a),
    }),
    {
      name: 'prompt-store',
    }
  )
);

export const useFileStore = create<FileSlice>()(
  devtools(
    (...a) => ({
      ...createFileSlice(...a),
    }),
    {
      name: 'file-store',
    }
  )
);

export const useFilterStore = create<FilterState>()(
  devtools(
    (...a) => ({
      ...createFilterSlice(...a),
    }),
    {
      name: 'filter-store',
    }
  )
);

export const useBulkTabStore = create<BulkTabSlice>()(
  persist(
    devtools(
      (...a) => ({
        ...createBulkTabSlice(...a),
      }),
      {
        name: 'bulk-tab-store',
      }
    ),
    {
      name: 'bulk-tab-store',
      partialize: (state) => {
        return {
          trackedBulks: state.trackedBulks,
        };
      },
    }
  )
);

export const useFormStore = create<FormSlice>()(
  devtools((...a) => createFormSlice()(...a), { name: 'form-store' })
);
