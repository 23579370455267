// Desktop/pigello-code/pigello-next/requests/api/get-list.ts

import { parseJsonFilter } from '@/hooks/use-table-filter/utils';
import { getGlobalFilterQuery } from '@/lib/api/globalFilter';
import { getConfig } from '@/lib/get-config';
import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';

import { fetchApi } from '.';
import { convertResponse } from '../instanceMapper';
import type { Meta } from '../types';
import type { GetListParams } from './types';
import {
  buildListBackendUrl,
  constructParams,
  handleApiResponse,
  processOrder,
  sanitizeFilters,
} from './utils';

export async function getList<T extends BaseInstance>({
  modelName,
  overrideUrl,
  overrideUrlReplaceAll,
  queryParams,
  signal,
}: GetListParams) {
  const config = getConfig<ModelName, T>(modelName, true);

  if (!config) throw new Error(`No config found for modelName: ${modelName}`);

  // Destructure query parameters
  const { skipGlobalFilters, ...restQueryParams } = queryParams;

  // Sanitize filters
  const sanitizedFilters = sanitizeFilters(queryParams.filters);

  // Process order
  if (queryParams.order) {
    queryParams.order = processOrder(queryParams.order, config);
  }
  // Turn the sanitized filters into a string
  const sanitizedFiltersString = parseJsonFilter(sanitizedFilters ?? {});
  // Construct query parameters
  const params = constructParams({
    ...restQueryParams,
    order: queryParams.order,
  });

  // Build backend URL
  const backendUrl = buildListBackendUrl(
    config,
    overrideUrl,
    overrideUrlReplaceAll
  );

  // Construct complete URL with global filters if applicable
  const globalFilterQuery =
    modelName !== 'organization'
      ? await getGlobalFilterQuery(skipGlobalFilters)
      : '';

  const completeUrl = `${backendUrl}?${params}${
    globalFilterQuery ? `${globalFilterQuery}` : ''
  }`;

  // Append JSON filters
  const finalUrl = `${completeUrl}&${sanitizedFiltersString.length > 0 ? sanitizedFiltersString : ''}`;

  const finalFinalUrl =
    finalUrl.endsWith('&') || finalUrl.endsWith('?')
      ? finalUrl.slice(0, -1)
      : finalUrl;

  // Fetch data from API
  const res = await fetchApi({
    method: 'GET',
    url: finalFinalUrl,
    signal,
  });

  // Handle API response
  const data = await handleApiResponse<{
    data: T[];
    meta: Meta;
  }>(res);
  // Convert and return the response
  return convertResponse({
    response: data,
    config,
  });
}
