import type { ModelName } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';
import type { IdentifierMapping } from '..';

export function getContractMapping(
  modelName: ModelName
): IdentifierMapping[string][string] {
  return {
    warning_contracts: {
      modelName,
      filters: {
        is_draft: {
          noop: false,
        },
        status: {
          __in: '7',
        },
      },
    },
    cancelled_contracts: {
      modelName,
      filters: {
        is_draft: {
          noop: false,
        },
        status: {
          __in: '3,4,5,6',
        },
      },
    },
    not_signed_contracts: {
      modelName,
      filters: {
        is_draft: {
          noop: false,
        },
        status: {
          __in: '10',
        },
      },
    },
    upcoming_contracts: {
      modelName,
      filters: {
        is_draft: {
          noop: false,
        },
        status: {
          __in: '9',
        },
        start_date: {
          __gt: DateTime.now().toISODate(),
        },
      },
    },
    currently_signed_contracts: {
      modelName: 'signabledocument',
      content_type: `contracts.${modelName}`,
      filters: {
        signing_initiated: { noop: true },
        signing_aborted_time: { __isnull: true },
        signed_time: { __isnull: true },
      },
    },
    cancelled_signed_contracts: {
      modelName: 'signabledocument',
      content_type: `contracts.${modelName}`,
      filters: {
        signing_initiated: { noop: false },
        signed_time: { __isnull: true },
        signing_aborted_time: { __isnull: false },
      },
    },
  };
}

export function getTenantMapping(
  modelName: ModelName,
  show_in_brf_context?: boolean
): Omit<IdentifierMapping[string][string], 'timeline_attributes'> {
  return {
    tenants_on_my_pages: {
      modelName,
      filters: {
        is_active: {
          noop: true,
        },
        ...(show_in_brf_context && {
          show_in_brf_context: {
            noop: true,
          },
        }),
      },
    },
    tenants_invited_to_my_pages: {
      modelName,
      filters: {
        is_active: {
          noop: false,
        },
        ...(show_in_brf_context && {
          show_in_brf_context: {
            noop: true,
          },
        }),
        hotp_invite_sendout_time: { __isnull: false },
      },
    },
    tenants_to_anonymize: {
      modelName,
      filters: {
        anonymized: {
          noop: false,
        },
        ...(show_in_brf_context && {
          show_in_brf_context: {
            noop: true,
          },
        }),
        earliest_active_date: {
          __lt: DateTime.now().toISODate(),
        },
        latest_active_date: {
          __lt: DateTime.now().toISODate(),
        },
      },
    },
  };
}

export function getObjectsMapping(
  modelName: ModelName
): IdentifierMapping[string][string] {
  return {
    vacancy_objects: {
      modelName,
      filters: {
        status: {
          __in: '9,10,11,12',
        },
      },
    },
    // no_taxations_record: {
    //   modelName: 'taxationrecord',
    // },
    no_swedish_price_base: {
      modelName: 'swedishpricebasevalue',
      filters: {
        for_year: { noop: DateTime.now().year },
      },
    },
  };
}

export function getInvoiceMapping(
  modelName: ModelName,
  non_rental_invoices?: boolean
): IdentifierMapping[string][string] {
  return {
    non_attested_invoices: {
      modelName,
      filters: {
        ...(non_rental_invoices && {
          rental_invoice: { noop: false },
        }),
        attested_date: { __isnull: true },
      },
    },
    not_paid_invoices: {
      modelName,
      filters: {
        ...(non_rental_invoices && {
          rental_invoice: { noop: false },
        }),
        attested_date: { __isnull: false },
        closed_time: { __isnull: true },
      },
    },
    overdue_invoices: {
      modelName,
      filters: {
        ...(non_rental_invoices && {
          rental_invoice: { noop: false },
        }),
        attested_date: { __isnull: false },
        closed_time: { __isnull: true },
        due_date: {
          __lt: DateTime.now().toISODate(),
        },
      },
    },
    merged_failed_invoices: {
      modelName: 'coinvoicenotation',
      filters: {
        kind: { noop: 1 },
        failed: { noop: true },
        invoice: { __isnull: false },
      },
      noFilterReturn: true,
    },
    merged_failed_coinvoice: {
      modelName: 'coinvoicenotation',
      filters: {
        kind: { noop: 0 },
        failed: { noop: true },
        invoice: { __isnull: false },
      },
      noFilterReturn: true,
    },
    autogiro_failed: {
      modelName: 'autogirostatus',
      filters: {
        status_flag: { noop: 2 },
      },
      noFilterReturn: true,
    },
    invoice_sendout_failed: {
      modelName: 'notificationdistribution',
      filters: {
        notification_content_types: {
          noop: 'accounting.invoice,accounting.debtinvoice',
        },
        // notification_object_ids: { __isnull: false },
        sent_time: { __isnull: true },
        failed_delivery_time: { __isnull: false },
        delivered_time: { __isnull: true },
        viewed_time: { __isnull: true },
      },
    },
  };
}
