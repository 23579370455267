import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { getCookies } from 'cookies-next';
import { useBulkTabStore } from '../';
import type { BulkWindowId, TrackedBulk } from '../types';

type MessageType = { id: string } & Record<string, unknown>;

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type MessageCallback = (payload: MessageType, evt: MessageEvent<any>) => void;

export type BulkTabOpenOptions<Instance extends BaseInstance> = {
  mode: TrackedBulk['mode'];
  config: IBaseInstanceConfig<Instance>;
  //for opening minimized bulk
  winId?: string;
} & (
  | {
      mode: 'create';
    }
  | {
      mode: 'copy';
      instances: (Instance | string)[];
    }
  | {
      mode: 'edit';
      instances: (Instance | string)[];
    }
  | {
      mode: 'download';
      columns: string[];
      settings?: {
        totalInstancesAmount: number;
        order: string[];
        query: Record<string, string>;
      };
      instances?: (Instance | string)[];
    }
);

export interface BulkTabSlice {
  // winId -> TrackedBulk
  trackedBulks: Record<BulkWindowId, TrackedBulk>;
  openNewBulk: <Instance extends BaseInstance>(
    opts: BulkTabOpenOptions<Instance>
  ) => void;
  hasEventListener: boolean;
  handleGlobalEventListener: VoidFunction;
  onUnload: VoidFunction;
  //winId -> msgId -> callbacks
  listeners: Record<BulkWindowId, Record<string, MessageCallback[]>>;
  onMessage: (winId: BulkWindowId, msgId: string, cb: MessageCallback) => void;
  activateTrackedBulk: (winId: BulkWindowId) => void;
  initialize: VoidFunction;
  clearTrackedBulk: (tracked: TrackedBulk) => void;
}

declare global {
  interface Window {
    _bulk_tabs_windows: Record<BulkWindowId, Window>;
  }
}

const BULK_TAB_TYPE_TEXT: Record<TrackedBulk['mode'], string> = {
  copy: 'kopierande',
  create: 'skapande',
  edit: 'redigerande',
  //won't be rendered
  download: 'download',
};

const BULK_TAB_ORIGINS: Record<string, string> = {
  local: 'http://localhost:5173',
  dev: 'https://import-demo.pigello.io',
  prod: 'https://import.pigello.io',
};

export const BULK_TAB_ORIGIN =
  BULK_TAB_ORIGINS[process.env.NEXT_PUBLIC_CLIENT_TYPE ?? 'prod'];

const BULK_TAB_HANDSHAKE_TRIES = 10;

export const getWindowUrl = (trackedBulk: TrackedBulk) => {
  const baseHostOrigin = `${window.location.protocol}//${window.location.host}`;
  const baseUrl = `${BULK_TAB_ORIGIN}/?id=${trackedBulk.id}&mode=${trackedBulk.mode}&modelName=${trackedBulk.modelName}&host=${baseHostOrigin}`;
  return baseUrl;
};

export const getWindowTarget = (trackedBulk: TrackedBulk) => {
  return `${trackedBulk.id}`;
};

const hasWindow = (winId: BulkWindowId) => {
  if (!window._bulk_tabs_windows) return false;

  return winId in window._bulk_tabs_windows;
};

export const registerBulkTabOnWindow = (winId: BulkWindowId, win: Window) => {
  if (!window._bulk_tabs_windows) window._bulk_tabs_windows = {};

  window._bulk_tabs_windows[winId] = win;
};

const getWindow = (winId: BulkWindowId) => {
  if (!hasWindow(winId))
    throw new Error('getWindow() was called without hasWindow()');
  return window._bulk_tabs_windows[winId];
};

export const sendMessage = (winId: BulkWindowId, payload: MessageType) => {
  if (!hasWindow(winId)) return;

  const win = getWindow(winId);

  win.postMessage(
    {
      ...payload,
    },
    BULK_TAB_ORIGIN
  );
};

export const getTrackedBulk = (
  winId: BulkWindowId
): TrackedBulk | undefined => {
  return useBulkTabStore.getState().trackedBulks[winId];
};

export const regainWindowReference = (winId: BulkWindowId) => {
  const tracked = getTrackedBulk(winId);

  if (!tracked) return false;

  const referenceWindow = window.open('', getWindowTarget(tracked));

  if (!referenceWindow) return false;

  registerBulkTabOnWindow(winId, referenceWindow);

  return true;
};

const setupDefaultListeners = (winId: BulkWindowId) => {
  useBulkTabStore.getState().onMessage(winId, 'ON_WINDOW_CLOSE', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    useBulkTabStore.getState().clearTrackedBulk(tracked);
  });

  useBulkTabStore.getState().onMessage(winId, 'ON_WINDOW_MINIMIZE', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    //tell window keeper provider
    window._minimize_bulk(tracked);
  });

  useBulkTabStore.getState().onMessage(winId, 'GET_AUTH_CREDENTIALS', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    const { token, user_id, customer_id, organization_id } = getCookies();

    sendMessage(tracked.id, {
      id: 'ON_AUTH_CREDENTIALS',
      credentials: {
        token,
        user_id,
        customer_id,
        organization_id,
      },
    });
  });

  useBulkTabStore.getState().onMessage(winId, 'FETCH_WINDOW_SETTINGS', () => {
    const tracked = getTrackedBulk(winId);

    if (!tracked) return;

    if (!tracked.instanceIds) {
      sendMessage(tracked.id, {
        id: 'ON_WINDOW_SETTINGS',
        data: {
          instanceIds: null,
        },
      });

      return;
    }

    sendMessage(tracked.id, {
      id: 'ON_WINDOW_SETTINGS',
      data: {
        instanceIds: tracked.instanceIds.join(','),
      },
    });
  });

  useBulkTabStore
    .getState()
    .onMessage(winId, 'FETCH_WINDOW_DOWNLOAD_SETTINGS', () => {
      const tracked = getTrackedBulk(winId);

      if (!tracked) return;

      sendMessage(tracked.id, {
        id: 'ON_WINDOW_DOWNLOAD_SETTINGS',
        data: tracked.downloadData,
      });
    });
};

export const establishConnection = (
  winId: BulkWindowId,
  reactivation = false
) => {
  if (!hasWindow(winId)) return;

  let count = 0;

  const interval = setInterval(() => {
    console.log('trying to establish connection with', winId, count);

    if (count >= BULK_TAB_HANDSHAKE_TRIES) {
      console.log('failed to activate bulk tab', winId);
      clearInterval(interval);
      return;
    }

    count++;

    sendMessage(winId, {
      id: reactivation ? 'ON_WINDOW_RE_ACTIVATION' : 'ON_WINDOW_ACTIVATION',
    });
  }, 1000);

  useBulkTabStore.getState().onMessage(winId, 'GOT_WINDOW_ACTIVATION', () => {
    clearInterval(interval);

    setupDefaultListeners(winId);

    useBulkTabStore.getState().activateTrackedBulk(winId);
  });
};

export const getTabTitleText = (mode: TrackedBulk['mode']) => {
  const prefix = 'Mas';
  const suffix = BULK_TAB_TYPE_TEXT[mode];

  if (suffix.startsWith('s')) {
    return `${prefix}${suffix}`;
  }

  return `${prefix}s${suffix}`;
};
