import type {
  BaseInstance,
  IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { cloneDeep } from 'lodash';
import qs from 'query-string';
import { BASE_BACKEND_URL } from '../constants';
import type { QueryParams } from '../types';
import type { ApiResponse } from './types';

export const getBytesOfString = (str: string) => new Blob([str]).size;

export function buildBackendUrl<T extends BaseInstance>(
  config: IBaseInstanceConfig<T>,
  id?: string,
  overrideUrl?: string
) {
  if (overrideUrl) {
    return `${BASE_BACKEND_URL}/${overrideUrl}/${id ? `${id}/` : ''}`;
  }
  return `${BASE_BACKEND_URL}/${config.instanceUrl}/${id ? `${id}/` : ''}`;
}

export function buildListBackendUrl<T extends BaseInstance>(
  config: IBaseInstanceConfig<T>,
  overrideUrl?: string,
  overrideUrlReplaceAll?: boolean
): string {
  if (overrideUrl) {
    if (overrideUrlReplaceAll) {
      const trimmedUrl = overrideUrl.endsWith('/')
        ? overrideUrl
        : `${overrideUrl}/`;
      return `${BASE_BACKEND_URL}/${trimmedUrl}`;
    }
    return `${BASE_BACKEND_URL}/${config.listUrl}/${overrideUrl}/`;
  }
  return `${BASE_BACKEND_URL}/${config.listUrl}/`;
}

export function constructParams({
  page,
  pageSize,
  search,
  order,
  slim,
  statistics,
  objectId,
}: QueryParams) {
  return qs.stringify(
    {
      _page: statistics ? undefined : (page ?? 1),
      _page_size: statistics ? undefined : (pageSize ?? 25),
      _search: search,
      _order_by: order,
      _slim: slim,
      _statistics: statistics,
      object_id: objectId,
    },
    {
      arrayFormat: 'comma',
      skipEmptyString: true,
      skipNull: true,
      encode: true,
    }
  );
}

export function sanitizeFilters(filters: QueryParams['filters']) {
  const clonedFilters = cloneDeep(filters);

  const filterKeys = ['id'] as const;

  for (const key of filterKeys) {
    const inKey = '__in';
    const notInKey = '__in!';

    const filter = clonedFilters?.[key];
    if (filter) {
      const processKey = (subKey: typeof inKey | typeof notInKey) => {
        const value = filter[subKey];
        if (typeof value === 'string' && value.includes(',')) {
          filter[subKey] = value.split(',').sort().join(',');
        }
      };

      processKey(inKey);
      processKey(notInKey);
    }
  }

  return clonedFilters;
}

export async function handleApiResponse<T>(res: ApiResponse<T>) {
  if (!res.ok) {
    const errorData = await res.json().catch(() => undefined);
    throw {
      status: res.status,
      message: res.statusText,
      url: res.url,
      errorData,
    };
  }
  return res.json();
}

export function processOrder<T extends BaseInstance>(
  order: QueryParams['order'],
  config: IBaseInstanceConfig<T>
): string[] | undefined {
  if (!order || !Array.isArray(order)) return order;
  return order
    .filter((fieldName) => {
      const cleanName = fieldName.startsWith('-')
        ? fieldName.slice(1)
        : fieldName;
      if (!(cleanName in config.fields)) {
        // no-dd-sa:typescript-best-practices/no-console
        console.warn(
          `Unable to find field: ${fieldName} in ${config.modelName}`
        );
        return false;
      }
      return true;
    })
    .map((fieldName) => {
      if (fieldName.startsWith('-')) {
        return `-${
          config.fields[fieldName.replace('-', '') as keyof BaseInstance]
            .externalFieldName
        }`;
      }
      return config.fields[fieldName as keyof BaseInstance].externalFieldName;
    });
}
