import type { ModelName } from '@pigello/pigello-matrix';
import type { StateCreator } from 'zustand';

export type OffLoadingSlice = {
  offLoadedModels: Partial<Record<ModelName, Set<string>>>;
  addOffLoadedIdForModel: (modelName: ModelName, id: string) => void;
  addOffLoadedIdsForModel: (modelName: ModelName, ids: string[]) => void;
  removeOffLoadedIdForModel: (modelName: ModelName, id: string) => void;
  getOffLoadedIdsForModel: (modelName: ModelName) => Set<string>;
  clearOffLoadedModel: (modelName: ModelName) => void;
  isObjectOffLoaded: (modelName: ModelName, id: string) => boolean;
};

export const createOffLoadingSlice: StateCreator<OffLoadingSlice> = (
  set,
  get
) => ({
  offLoadedModels: {},
  removeOffLoadedIdForModel: (modelName, id) => {
    const modelSet = get().offLoadedModels[modelName] ?? new Set();
    modelSet.delete(id);
    set((state) => ({
      ...state,
      offLoadedModels: {
        ...state.offLoadedModels,
        [modelName]: modelSet,
      },
    }));
  },
  addOffLoadedIdForModel: (modelName, id) => {
    const modelSet = get().offLoadedModels[modelName] ?? new Set();
    modelSet.add(id);
    set((state) => ({
      ...state,
      offLoadedModels: {
        ...state.offLoadedModels,
        [modelName]: modelSet,
      },
    }));
  },
  addOffLoadedIdsForModel: (modelName, ids) => {
    const modelSet = get().offLoadedModels[modelName] ?? new Set();
    for (const id of ids) {
      modelSet.add(id);
    }
    set((state) => ({
      ...state,
      offLoadedModels: {
        ...state.offLoadedModels,
        [modelName]: modelSet,
      },
    }));
  },
  getOffLoadedIdsForModel: (modelName) => {
    return get().offLoadedModels[modelName] ?? new Set();
  },
  clearOffLoadedModel: (modelName) => {
    set((state) => ({
      ...state,
      offLoadedModels: {
        ...state.offLoadedModels,
        [modelName]: new Set(),
      },
    }));
  },
  isObjectOffLoaded: (modelName, id) => {
    return get().offLoadedModels[modelName]?.has(id) ?? false;
  },
});
