import type { GlobalFilterItem } from '@/components/global-filter/global-filter-modal';
import { getQueryClient } from '@/providers/queryClient';
import { getApi } from '@/requests/api';
import { BASE_BACKEND_URL, QUERY_KEYS } from '@/requests/constants';
import { getCookies } from '@/requests/cookies';
import type { Meta } from '@/requests/types';
import {
  CompanyConfig,
  RealEstateConfig,
  type Company,
  type RealEstate,
} from '@pigello/pigello-matrix';
import { setCookie } from 'cookies-next';
import queryString from 'query-string';

export const addGlobalRealestateFilter = (realestateId: string) =>
  addGlobalFilter('global_realestates', realestateId);

export const removeGlobalRealestateFilter = (realestateId: string) =>
  removeGlobalFilter('global_realestates', realestateId);

export const addGlobalSegmentFilter = (segmentId: string) =>
  addGlobalFilter('global_segments', segmentId);

export const removeGlobalSegmentFilter = (segmentId: string) =>
  removeGlobalFilter('global_segments', segmentId);

type GlobalFilter = {
  [key: string]: string[];
  global_realestates: string[];
  global_segments: string[];
  filter_external_clients: string[];
  global_companies: string[];
};

function getPageParams(page = 1, pageSize = 100) {
  return {
    _page: page,
    _page_size: pageSize,
  };
}

function buildQueryString(
  queryParams: Record<string, string | string[] | number | boolean>
) {
  return queryString.stringify(queryParams, {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: 'comma',
  });
}
export const getCurrentGlobalFilters = async () => {
  const { cookies } = await getCookies();

  let currentFiltering: GlobalFilter = {
    global_realestates: [],
    global_segments: [],
    filter_external_clients: [],
    global_companies: [],
  };

  let currentFilteringStr = cookies.globalFilter;

  if (!currentFilteringStr)
    currentFilteringStr = JSON.stringify({
      global_realestates: [],
      global_segments: [],
      filter_external_clients: [],
      global_companies: [],
    });

  try {
    currentFiltering = JSON.parse(currentFilteringStr);
  } catch (err) {
    console.log('unable to parse global filters! falling back...');
  }

  return currentFiltering;
};

export const resetGlobalFilters = () => {
  setCookie(
    'globalFilter',
    JSON.stringify({
      global_realestates: [],
      global_segments: [],
      filter_external_clients: [],
      global_companies: [],
    })
  );
};

const setCurrentGlobalFilters = (filtering: GlobalFilter) => {
  setCookie('globalFilter', JSON.stringify(filtering));
};

export const addGlobalFilters = async (filterItems: GlobalFilterItem[]) => {
  resetGlobalFilters();

  const currentFiltering = await getCurrentGlobalFilters();

  if (filterItems?.length) {
    for (let i = 0; i < filterItems.length; i++) {
      const current = filterItems[i];
      const key = current.type;
      const id = current.id;

      if (currentFiltering[key].includes(id)) continue;

      currentFiltering[key] = [...currentFiltering[key], id];
    }
  }

  setCurrentGlobalFilters(currentFiltering);
};

export const addGlobalFilter = async (key: string, id: string) => {
  const currentFiltering = await getCurrentGlobalFilters();

  if (currentFiltering[key].includes(id)) return;

  currentFiltering[key] = [...currentFiltering[key], id];

  setCurrentGlobalFilters(currentFiltering);
};

const removeGlobalFilter = async (key: string, id: string) => {
  const currentFiltering = await getCurrentGlobalFilters();

  if (!currentFiltering[key].includes(id)) return;

  currentFiltering[key].splice(currentFiltering[key].indexOf(id), 1);

  setCurrentGlobalFilters(currentFiltering);
};

const GLOBAL_FILTER_KEYS = ['global_realestates', 'global_segments'];

export const getGlobalFilterQuery = async (skipGlobalFilters?: boolean) => {
  const { cookies } = await getCookies();

  const queryClient = getQueryClient();

  const organizationId = cookies.organization_id;

  let query = `&organization=${organizationId}`;

  if (skipGlobalFilters) {
    return query;
  }
  const currentFiltering = await getCurrentGlobalFilters();

  // Handle external clients filtering
  if (currentFiltering.filter_external_clients?.length > 0) {
    try {
      const query = buildQueryString({
        ...getPageParams(),
        managed_by__in: currentFiltering.filter_external_clients,
        _slim: true,
      });

      const companyUrl = `${BASE_BACKEND_URL}/${CompanyConfig.listUrl}/?${query}`;
      const companies = await queryClient.ensureQueryData({
        queryKey: [
          CompanyConfig.modelName,
          QUERY_KEYS.GLOBAL_FILTER,
          currentFiltering.filter_external_clients,
        ],
        queryFn: () =>
          getApi<{
            meta: Meta;
            data: Pick<Company, 'id'>[];
          }>({
            url: companyUrl,
          }),
      });

      const companyIds = companies?.data.map((c) => c.id);

      if (!companyIds) return;

      if (companyIds.length > 0) {
        const query = buildQueryString({
          ...getPageParams(),
          owned_by__in: companyIds,
          _slim: true,
        });
        const realEstatesUrl = `${BASE_BACKEND_URL}/${RealEstateConfig.listUrl}/?${query}`;
        const realEstates = await queryClient.ensureQueryData({
          queryKey: [
            RealEstateConfig.modelName,
            QUERY_KEYS.GLOBAL_FILTER,
            query,
          ],
          queryFn: () =>
            getApi<{
              meta: Meta;
              data: Pick<RealEstate, 'id'>[];
            }>({
              url: realEstatesUrl,
            }),
        });
        const realEstateIds = realEstates?.data.map((r) => r.id);

        if (!realEstateIds) return;

        if (realEstateIds.length > 0) {
          currentFiltering.global_realestates = [
            ...(currentFiltering.global_realestates || []),
            ...realEstateIds,
          ];
        }
      }
    } catch (e) {
      console.info('Could not filter by external clients: ', e);
    }
  }

  // Handle companies filtering
  if (currentFiltering.global_companies?.length > 0) {
    try {
      const companyIds = currentFiltering.global_companies;
      const query = buildQueryString({
        ...getPageParams(),
        owned_by__in: companyIds,
        _slim: true,
      });
      const realEstatesUrl = `${BASE_BACKEND_URL}/${RealEstateConfig.listUrl}/?${query}`;
      const realEstates = await queryClient.ensureQueryData({
        queryKey: [RealEstateConfig.modelName, QUERY_KEYS.GLOBAL_FILTER, query],
        queryFn: () =>
          getApi<{
            meta: Meta;
            data: Pick<RealEstate, 'id'>[];
          }>({
            url: realEstatesUrl,
          }),
      });

      const realEstateIds = realEstates?.data.map((r) => r.id);

      if (!realEstateIds) return;

      if (realEstateIds.length > 0) {
        currentFiltering.global_realestates = [
          ...(currentFiltering.global_realestates || []),
          ...realEstateIds,
        ];
      }
    } catch (e) {
      console.info('Could not filter by companies: ', e);
    }
  }

  for (const key in currentFiltering) {
    if (
      GLOBAL_FILTER_KEYS.includes(key) &&
      currentFiltering[key] &&
      currentFiltering[key].length > 0
    ) {
      query += `&${key}=${currentFiltering[key].join(',')}`;
    }
  }

  return query;
};
