import {
  matrixConfigs,
  type BaseInstance,
  type IBaseInstanceConfig,
} from '@pigello/pigello-matrix';

// Overload signatures
export function getConfig<
  T extends keyof typeof matrixConfigs,
  I extends BaseInstance,
>(modelname: T, isGeneric?: true): IBaseInstanceConfig<I> | undefined;

export function getConfig<T extends keyof typeof matrixConfigs>(
  modelname: T,
  isGeneric?: false
): (typeof matrixConfigs)[T]['config'] | undefined;

// Implementation
export function getConfig<
  T extends keyof typeof matrixConfigs,
  I extends BaseInstance,
>(modelname: T, isGeneric?: boolean) {
  if (!modelname || (modelname && !(modelname in matrixConfigs))) {
    return undefined;
  }
  if (isGeneric) {
    return matrixConfigs[modelname].config as IBaseInstanceConfig<I>;
  }
  return matrixConfigs[modelname].config;
}
